import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import vi from 'locales/vi.json';
import en from 'locales/en.json';
import ja from 'locales/ja.json';
import { getListLanguageByEnvironment } from 'api/language';
import { handleErrorMessage } from './index';

const optionInit = process.env.REACT_APP_ENV === 'product' ? { lng: 'ja' } : { fallbackLng: 'ja' };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ...optionInit,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      vi: { translation: vi },
      en: { translation: en },
      ja: { translation: ja },
    },
  });
export const setResourceI18n = async () => {
  try {
    const { data: dataVi } = await getListLanguageByEnvironment({
      total: 1,
      env: ['CMS'],
      isDownloaded: 1,
      code: 'vi',
    });
    const { data: dataEn } = await getListLanguageByEnvironment({
      total: 1,
      env: ['CMS'],
      isDownloaded: 1,
      code: 'en',
    });
    const { data: dataJa } = await getListLanguageByEnvironment({
      total: 1,
      env: ['CMS'],
      isDownloaded: 1,
      code: 'ja',
    });
    i18n.addResourceBundle('vi', 'translation', dataVi, true, true);
    i18n.addResourceBundle('en', 'translation', dataEn, true, true);
    i18n.addResourceBundle('ja', 'translation', dataJa, true, true);
  } catch (error) {
    handleErrorMessage(error);
  }
};
