export enum Status {
  Active = 1,
  Inactive = 0,
}

export enum permissions {
  MANAGER_RESOURCE = 1,
  MANAGER_PERMISSION = 2,
  MANAGER_MEMBER = 3,
  MANAGER_USER = 4,
  MANAGER_CONFIG = 5,
  MANAGER_GROUP = 6,
  MANAGER_MESSAGE = 7,
  MANAGER_CONTACT = 8,
  MANAGER_REPORT = 9,
  MANAGER_NOTIFICATION = 10,
  MANAGER_LANGUAGE = 11,
  VERIFY_MANAGEMENT = 12,
  PAYMENT_MANAGEMENT = 13,
}

export enum IsSystem {
  Yes = 1,
  No = 0,
}

export enum IsVisible {
  Yes = 1,
  No = 0,
}

export enum OptionalServiceType {
  SHIPPING = 1,
  SHOPPING = 2,
  BOTH = 3,
}
export enum ShoppingType {
  BUY = 1,
  SHIP = 2,
}
export enum OrderPaymentStatus {
  PENDING = 1, // cho client thanh toan hoa don
  CONFIRMED = 2, // client da thanh toan
  ADDITIONAL = 3, // Thanh toán bo sung
}
export enum CustomerAccountType {
  PERSONAL = 1,
  BUSINESS = 2,
}
export enum OrderStatus {
  REQUESTED = 1,
  PAYMENT_PENDING = 2,
  PURCHASING = 3,
  IMPORTED = 4,
  CREATE_TRANSPORT_ORDER = 41,
  PAYMENT_CONFIRMED = 5,
  DELIVERING = 6,
  COMPLETED = 7,
  DELIVERY_FAILED = 8,
}
export enum ConfigKeys {
  RESOURCE_VERSION = 'RESOURCE_VERSION',
  LANGUAGE_VERSION = 'LANGUAGE_VERSION',
  DATE_REQUEST_BUSINESS_PAYMENT = 'DATE_REQUEST_BUSINESS_PAYMENT',
  CURRENCY_RATE = 'CURRENCY_RATE',
  SUBSCRIPTION_DOMESTIC_PRICE = 'SUBSCRIPTION_DOMESTIC_PRICE',
  SUBSCRIPTION_FOREIGN_PRICE = 'SUBSCRIPTION_FOREIGN_PRICE',
  COMMON_SERVICE_FEE_FOREIGN_BUSINESS = 'COMMON_SERVICE_FEE_FOREIGN_BUSINESS',
  COMMON_SERVICE_FEE_FOREIGN_PERSONAL = 'COMMON_SERVICE_FEE_FOREIGN_PERSONAL',
  SHIPPING_FEE_FOREIGN_BUSINESS = 'SHIPPING_FEE_FOREIGN_BUSINESS',
  SHIPPING_FEE_FOREIGN_PERSONAL = 'SHIPPING_FEE_FOREIGN_PERSONAL',
  TRIAL_DURATION = 'TRIAL_DURATION',
}

export enum InvoiceType {
  QUOTATION = 1,
  ADDITIONAL = 2,
  GOODS = 3,
  DELIVERY = 4,
}

export enum OrderCommentConversationType {
  CUSTOMER_ADMIN = 1,
  ADMIN_ADMIN = 2,
}

export enum OrderCommentSenderType {
  CUSTOMER = 1,
  ADMIN = 2,
}

export enum InvoiceStatus {
  CANCELED = 0,
  REFER = 1,
  APPLYING = 2,
  PAID = 3,
}

export enum OrderRequestPaymentType {
  ADMIN = 0,
  CUSTOMER = 1,
}
export enum Continent {
  ASIA = 1,
  EUROPE = 2,
  AMERICA = 3,
  AUSTRALIA = 4,
  AFRICA = 5,
}
export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum LANGUAGE {
  JA = 'ja',
  EN = 'en',
}

export enum CustomerLocationType {
  DOMESTIC = 1,
  FOREIGN = 2,
}

export enum OrderDocumentStatus {
  DRAFT = 1,
  COMPLETED = 2,
}

export enum OptionalServiceCode {
  STORAGE_FEE = 'STORAGE_FEE',
  DOCUMENT = 'DOCUMENT',
  LIMIT_ITEM = 'LIMIT_ITEM',
}

export enum OrderType {
  SHIPPING = 1,
  SHOPPING = 2,
}

export enum RESOURCE_TYPE {
  MALE_BODY_SHAPE = 1,
  FEMALE_BODY_SHAPE = 2,
  MALE_PERSONALITY = 3,
  FEMALE_PERSONALITY = 4,
  MALE_SOCIABILITY = 5,
  FEMALE_SOCIABILITY = 6,
  EDUCATION = 7,
  HOLIDAY = 8,
  OCCUPATION = 9,
  LIQUOR = 10,
  TOBACCO = 11,
  BLOOD_TYPE = 12,
  MARRIAGE_HISTORY = 13,
  HOUSEMATE = 14,
  LANGUAGE_TO_USE = 15,
  FIRST_DATE_COST = 16,
  POSSIBLE_TO_MOVE_IN_THE_FUTURE = 17,
  FOCUS_ON_FINDING_A_PARTNER = 18,
  HOPE_UNTIL_WE_MEET = 19,
  KEN = 20,
  QUESTION = 21,
  REPORT = 22,
  CONTACT = 23,
  OUT_GROUP = 24,
  OS = 25,
  INCOME = 26,
  FAQ = 27,
  NG_WORD = 32,
}

export enum SEX {
  MALE = 1,
  FEMALE = 2,
}

export enum AGE_VERIFIED {
  WAIT_FOR_VERIFY = 1,
  VERIFY = 2,
  REJECT_VERIFY = 3,
}

export enum IMAGE_VERIFIED {
  VERIFY = 1,
  NOT_VERIFIED = 0,
}

export enum STATUS_CONTACT {
  SEEN = 1,
  UNSEEN = 0,
}

export enum STATUS_REPORT {
  PENDING = 0,
  BLOCK = 1,
  REJECT = 2,
}

export enum TYPE_PAYMENT {
  BUY_POINT = 1,
  TRADE_LIKE = 2,
  TRADE_BOOTS = 3,
  ADMIN_GIFT_POINT = 4,
}

export enum VERIFY_CHAT {
  CHAT_VERIFIED = 1,
  CHAT_UNVERIFIED = 0,
}

export enum MESSAGE_TYPE {
  TEXT = 0,
  IMAGE = 1,
}

export enum VERIFY_CHAT_STATUS {
  ACCEPT = 1,
  REJECT = 2,
}

export enum TYPE_NOTIFICATION {
  ALL = 1,
  MALE = 2.1,
  FEMALE = 2.2,
  USER_SELECTED = 3,
}

export enum STATUS_MEMBER {
  ACTIVE = 1,
  BLOCK = 2,
  BLOCK_FOREVER = 3,
}

export enum CONTACT_TYPE {
  GUEST = 1,
  MEMBER = 2,
}
