import React, { Suspense, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import RootWrapper from 'wrappers/RootWrapper';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import configs from 'config';

export const history = createBrowserHistory();
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
    },
  },
});
function App() {
  useEffect(() => {
    const barcodeHandler = (event: any) => {
      // alert(event.detail.code);
    };
    window.addEventListener('barcode', barcodeHandler);
    return () => {
      window.removeEventListener('barcode', barcodeHandler);
    };
  }, []);
  return (
    <QueryClientProvider client={client}>
      <Router history={history}>
        <Suspense fallback={null}>
          <RootWrapper />
          {configs.APP_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
