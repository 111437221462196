import React, { useEffect, useState } from 'react';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';
import IconMaps from 'components/IconMaps';
import { useTranslation } from 'react-i18next';
import { permissions } from 'common/enum';
import logo from 'assets/images/LOGO_APP.png';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export default function SideNav({ sidebarShow, onShowChange }: any) {
  const { t } = useTranslation();
  const [listPermissions, setListPermissions] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    const listPermissionUser: any = localStorage.getItem('permissions')?.split(',');
    setListPermissions(listPermissionUser.map((x: any) => Number(x)));
  }, []);
  const routes = JSON.parse(
    JSON.stringify([
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.member'),
        route: '/members',
        icon: 'Member',
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.member_manage'),
            to: '/member/member-manage',
            icon: 'MemberManage',
            visible: !!listPermissions?.includes(permissions.MANAGER_MEMBER),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.member_censorship'),
            to: '/member/member-censorship',
            icon: 'MemberCensorship',
            visible: !!listPermissions?.includes(permissions.MANAGER_MEMBER),
          },
        ],
        visible: !!listPermissions?.includes(permissions.MANAGER_MEMBER),
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.payment'),
        to: '/payment',
        icon: 'Payment',
        visible: !!listPermissions?.includes(permissions.PAYMENT_MANAGEMENT),
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.message'),
        icon: 'Chat',
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.chat_verify'),
            to: '/chat/chat-verify',
            visible: !!listPermissions?.includes(permissions.VERIFY_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.chat_conversation'),
            to: '/chat/chat-conversation',
            visible: !!listPermissions?.includes(permissions.MANAGER_MESSAGE),
          },
        ],
        visible:
          !!listPermissions?.includes(permissions.VERIFY_MANAGEMENT) ||
          !!listPermissions?.includes(permissions.MANAGER_MESSAGE),
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.contact'),
        to: '/contact',
        icon: 'Contact',
        visible: !!listPermissions?.includes(permissions.MANAGER_CONTACT),
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.report'),
        to: '/report',
        icon: 'Report',
        visible: !!listPermissions?.includes(permissions.MANAGER_REPORT),
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.notification'),
        to: '/notification',
        icon: 'Notification',
        visible: !!listPermissions?.includes(permissions.MANAGER_NOTIFICATION),
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: t('nav.customer'),
      //   to: '/customer',
      //   icon: 'Customer',
      //   visible: !!!listPermissions?.includes(permissions.RESOURCE_MANAGEMENT),
      //   badge: {
      //     color: 'success',
      //     text: '4',
      //   },
      // },
      // {
      //   _tag: 'CSidebarNavDropdown',
      //   name: t('nav.payment'),
      //   route: '/payment',
      //   icon: 'Payment',
      //   visible: !!!listPermissions?.includes(permissions.RESOURCE_MANAGEMENT),
      //   _children: [],
      // },

      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.system'),
        icon: 'System',
        visible:
          !!listPermissions?.includes(permissions.MANAGER_USER) ||
          !!listPermissions?.includes(permissions.MANAGER_LANGUAGE) ||
          !!listPermissions?.includes(permissions.MANAGER_PERMISSION),
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.staff'),
            to: '/staffs',
            icon: 'UserOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_USER),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.language'),
            to: '/languages',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_LANGUAGE),
          },

          {
            _tag: 'CSidebarNavItem',
            name: t('nav.role'),
            to: '/roles',
            visible: !!listPermissions?.includes(permissions.MANAGER_PERMISSION),
          },
        ],
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.setting'),
        icon: 'Setting',
        visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.configs_admin'),
            to: '/configs-admin',
            visible: !!listPermissions?.includes(permissions.MANAGER_CONFIG),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.like_package'),
            to: '/like-package',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.boost_package'),
            to: '/boost-package',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          // {
          //   _tag: 'CSidebarNavItem',
          //   name: t('nav.configs_system'),
          //   to: '/configs-system',
          //   visible: !!listPermissions?.includes(permissions.MANAGER_CONFIG),
          // },

          {
            _tag: 'CSidebarNavItem',
            name: t('nav.male_body_shape'),
            to: '/resources/male-body-shape',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.female_body_shape'),
            to: '/resources/female-body-shape',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.male_personality'),
            to: '/resources/male-personality',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.female_personality'),
            to: '/resources/female-personality',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.male_sociability'),
            to: '/resources/male-sociability',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.female_sociability'),
            to: '/resources/female-sociability',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.education'),
            to: '/resources/education',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.holiday'),
            to: '/resources/holiday',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.occupation'),
            to: '/resources/occupation',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.liquor'),
            to: '/resources/liquor',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.tobacco'),
            to: '/resources/tobacco',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.blood_type'),
            to: '/resources/blood-type',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.marriage_history'),
            to: '/resources/marriage-history',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.housemate'),
            to: '/resources/housemate',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.language_to_use'),
            to: '/resources/language-to-use',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.first_date_cost'),
            to: '/resources/first-date-cost',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.possible_move'),
            to: '/resources/possible-move',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.focus_finding'),
            to: '/resources/focus-finding',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.hope_meet'),
            to: '/resources/hope-meet',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.ken'),
            to: '/resources/ken',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.question'),
            to: '/resources/question',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.report_resource'),
            to: '/resources/report',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.contact_resource'),
            to: '/resources/contact',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.out_group'),
            to: '/resources/out-group',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.OS'),
            to: '/resources/OS',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.income'),
            to: '/resources/income',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.FAQ'),
            to: '/resources/FAQ',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.ng_word'),
            to: '/resources/ng-word',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.MANAGER_RESOURCE),
          },
        ],
      },
    ]),
    (key, value: any) => {
      if (key === 'icon') {
        return IconMaps[value];
      }
      return value;
    }
  );
  useEffect(() => {
    if (history.location.pathname === '/') {
      const firstItem = routes.find((item: any) => {
        return item.visible;
      });
      if (firstItem?.hasOwnProperty('_children')) {
        const link = firstItem?._children.find((subItem: any) => subItem.visible);
        history.push(link?.to);
      } else {
        history.push(firstItem?.to);
      }
    }
  }, [history.location.pathname, listPermissions]);

  const visibleRoutes = routes.filter((item: any) => {
    return !!item.visible;
  });

  const visibleRoutesChildren = visibleRoutes.map((item: any) => {
    if (item.hasOwnProperty('_children')) {
      const arrSubItem = item._children.filter((subItem: any) => !!subItem.visible);
      return { ...item, _children: arrSubItem };
    }
    return item;
  });

  return (
    <CSidebar
      minimize={sidebarShow}
      // onShowChange={(val: any) => onShowChange(val)}
      onMinimizeChange={(value: any) => console.log(value)}
      size={'lg'}
    >
      <CSidebarBrand to="/">
        <div className="logoHead">
          <span className="logoText">DAIN</span>
          {/* <img src={logo} alt="" className="img-fluid" /> */}
        </div>
      </CSidebarBrand>
      <CSidebarNav className="hNav">
        <CCreateElement
          items={visibleRoutesChildren}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
}
