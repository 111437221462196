import React, { lazy, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';
import { setResourceI18n } from 'helper/i18n';
import { getListLanguages, getListEnvironments } from 'api/language';
import LoadingSpinner from 'components/LoadingSpinner';

// Pages
const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const Page404 = lazy(() => import('pages/Page404'));
const Page500 = lazy(() => import('pages/Page500'));

export default function AppWrapper() {
  const [ready, setReady] = useState(false);
  const { data: listLanguages } = useQuery('listLanguages', getListLanguages);

  useEffect(() => {
    setResourceI18n().then(() => {
      setReady(true);
    });
  }, []);

  if (!ready && !!!listLanguages) return <LoadingSpinner />;

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/404" component={Page404} />
      <Route path="/500" component={Page500} />
      <Route path="/" component={AuthWrapper} />
    </Switch>
  );
}
