import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function HelmetHeader() {
  const { t } = useTranslation();
  const getHelmetHeader = (pathname: string) => {
    switch (pathname) {
      case '/member/member-manage':
        return t('nav.member_manage');
      case '/member/member-censorship':
        return t('nav.member_censorship');
      case '/payment':
        return t('nav.payment');
      case '/chat/chat-verify':
        return t('nav.chat_verify');
      case '/chat/chat-conversation':
        return t('nav.chat_conversation');
      case '/contact':
        return t('nav.contact');
      case '/report':
        return t('nav.report');
      case '/notification':
        return t('nav.notification');
      case '/staffs':
        return t('nav.staff');
      case '/languages':
        return t('nav.language');
      case '/roles':
        return t('nav.role');
      case '/configs-admin':
        return t('nav.configs_admin');
      case '/like-package':
        return t('nav.like_package');
      case '/boost-package':
        return t('nav.boost_package');
      case '/resources/male-body-shape':
        return t('nav.male_body_shape');
      case '/resources/female-body-shape':
        return t('nav.female_body_shape');
      case '/resources/male-personality':
        return t('nav.male_personality');
      case '/resources/female-personality':
        return t('nav.female_personality');
      case '/resources/male-sociability':
        return t('nav.male_sociability');
      case '/resources/female-sociability':
        return t('nav.female_sociability');
      case '/resources/education':
        return t('nav.education');
      case '/resources/holiday':
        return t('nav.holiday');
      case '/resources/occupation':
        return t('nav.occupation');
      case '/resources/liquor':
        return t('nav.liquor');
      case '/resources/tobacco':
        return t('nav.tobacco');
      case '/resources/blood-type':
        return t('nav.blood_type');
      case '/resources/marriage-history':
        return t('nav.marriage_history');
      case '/resources/housemate':
        return t('nav.housemate');
      case '/resources/language-to-use':
        return t('nav.language_to_use');
      case '/resources/first-date-cost':
        return t('nav.first_date_cost');
      case '/resources/possible-move':
        return t('nav.possible_move');
      case '/resources/focus-finding':
        return t('nav.focus_finding');
      case '/resources/hope-meet':
        return t('nav.hope_meet');
      case '/resources/ken':
        return t('nav.ken');
      case '/resources/question':
        return t('nav.question');
      case '/resources/report':
        return t('nav.report_resource');
      case '/resources/contact':
        return t('nav.contact_resource');
      case '/resources/out-group':
        return t('nav.out_group');
      case '/resources/OS':
        return t('nav.OS');
      case '/resources/income':
        return t('nav.income');
      case '/resources/FAQ':
        return t('nav.FAQ');
      case '/account/profile':
        return t('nav.profile');
      case '/account/change-password':
        return t('nav.change_password');
      default:
        break;
    }
  };
  return (
    <Helmet>
      <title>{getHelmetHeader(window.location.pathname)}</title>
    </Helmet>
  );
}
