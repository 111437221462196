import configs from 'config';
import CustomNotification from 'components/CustomNotification';
import {
  ConfigKeys,
  CustomerAccountType,
  InvoiceStatus,
  OptionalServiceType,
  OrderStatus,
  CustomerLocationType,
  OrderPaymentStatus,
} from 'common/enum';
import i18n from 'i18next';

export const handleErrorMessage = (error: any) => {
  CustomNotification({
    type: 'error',
    message: i18n.t('common.error'),
    description: getErrorMessage(error),
  });
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  const errorKey = error?.response?.data?.errorKey;
  return errorKey ? i18n.t(`ErrorCode.${errorKey}`) : 'Something went wrong!';
};

export const assignDomain = (url: string) => {
  if (url?.startsWith('http')) return url;
  return `${configs.AWS_DOMAIN}/${url}`;
};

export const getBase64 = (img: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const getDataFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsText(file);
  });

export const convertOptionalServiceType = (optionType: number, t: any) => {
  switch (optionType) {
    case OptionalServiceType.SHIPPING:
      return t('option.shipping');
    case OptionalServiceType.SHOPPING:
      return t('option.shopping');
    case OptionalServiceType.BOTH:
      return t('option.both');
    default:
      return t('option.shipping');
  }
};

export const convertShoppingStep = (trackingType: number, t: any) => {
  switch (trackingType) {
    case OrderStatus.REQUESTED:
      return t('option.requested');
    case OrderStatus.PAYMENT_PENDING:
      return t('option.payment_pending');
    case OrderStatus.PURCHASING:
      return t('option.purchasing');
    case OrderStatus.IMPORTED:
      return t('option.imported');
    case OrderStatus.CREATE_TRANSPORT_ORDER:
      return t('option.create_transport_order');
    case OrderStatus.PAYMENT_CONFIRMED:
      return t('option.imported');
    case OrderStatus.DELIVERING:
      return t('option.delivering');
    case OrderStatus.COMPLETED:
      return t('option.order_done');
    case OrderStatus.DELIVERY_FAILED:
      return t('option.order_done');
    default:
      return '';
  }
};

// export const convertDate = (date: string) => {
//   return date ? moment(date).format('YYYY-MM-DD') : '';
// };

export const convertAccountType = (accountType: CustomerAccountType, t: any) => {
  switch (accountType) {
    case CustomerAccountType.PERSONAL:
      return t('option.personal');
    case CustomerAccountType.BUSINESS:
      return t('option.business');
    default:
      return t('option.personal');
  }
};

export const convertLocationType = (accountType: CustomerLocationType, t: any) => {
  switch (accountType) {
    case CustomerLocationType.DOMESTIC:
      return t('option.domestic');
    case CustomerLocationType.FOREIGN:
      return t('option.foreign');
    default:
      return t('option.foreign');
  }
};

export const getConfigByKeys = (dataConfig: Array<any>, key: string) => {
  return dataConfig?.find((item) => item.key === key);
};

export const getProcessFeeKey = (accountType: CustomerAccountType) => {
  return accountType === CustomerAccountType.BUSINESS
    ? ConfigKeys.COMMON_SERVICE_FEE_FOREIGN_BUSINESS
    : ConfigKeys.COMMON_SERVICE_FEE_FOREIGN_PERSONAL;
};

export const getDeliveryFeeKey = (accountType: CustomerAccountType) => {
  return accountType === CustomerAccountType.BUSINESS
    ? ConfigKeys.SHIPPING_FEE_FOREIGN_BUSINESS
    : ConfigKeys.SHIPPING_FEE_FOREIGN_PERSONAL;
};

export const convertInvoiceStatus = (invoiceStatus: InvoiceStatus, t: any) => {
  switch (invoiceStatus) {
    case InvoiceStatus.CANCELED:
      return t('option.canceled');
    case InvoiceStatus.REFER:
      return t('option.payment_pending');
    case InvoiceStatus.APPLYING:
      return t('option.payment_pending');
    case InvoiceStatus.PAID:
      return t('option.paid');
    default:
      return t('option.payment_pending');
  }
};

export function renderPriceOptionService(dataItem: any, accountType: number, locationType: number) {
  let price = 0;
  if (locationType === CustomerLocationType.DOMESTIC) {
    price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalDomesticPrice : dataItem.businessDomesticPrice;
  }
  if (locationType === CustomerLocationType.FOREIGN) {
    price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalForeignPrice : dataItem.businessForeignPrice;
  }
  return price;
}

export function convertPrice(dataItem: any, accountType: number, locationType: number, currentCyRate: any) {
  let price = 0;
  if (locationType === CustomerLocationType.DOMESTIC) {
    price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalDomesticPrice : dataItem.businessDomesticPrice;
    const money = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }).format(price);
    return money;
  }
  if (locationType === CustomerLocationType.FOREIGN) {
    price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalForeignPrice : dataItem.businessForeignPrice;
    price = Number(price);
    currentCyRate = Number(currentCyRate);
    const result = price / currentCyRate;
    const money = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(result);
    return money;
  }
}

export function convertPriceJP(price: number, currentCyRate: any) {
  price = price || 0;
  const moneyJP = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(price);
  const convertPriceJPToUSD = Number(price) / Number(currentCyRate);
  const moneyUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(convertPriceJPToUSD);
  const renderPrice = `${moneyJP} (${moneyUSD})`;
  return renderPrice;
}

export function getPriceForCustomer(dataItem: any, accountType: number, locationType: number) {
  if (locationType === CustomerLocationType.DOMESTIC) {
    const price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalDomesticPrice : dataItem.businessDomesticPrice;
    return price;
  }
  if (locationType === CustomerLocationType.FOREIGN) {
    const price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalForeignPrice : dataItem.businessForeignPrice;
    return price;
  }
}

export const getPaymentStatus = (status: OrderPaymentStatus, t: any) => {
  switch (status) {
    case OrderPaymentStatus.PENDING:
      return t('option.payment_pending');
    case OrderPaymentStatus.CONFIRMED:
      return t('option.payment_confirmed');
    case OrderPaymentStatus.ADDITIONAL:
      return t('option.payment_additional');
    default:
      return t('option.payment_pending');
  }
};

export const getMinimumVendorFee = (vendorQuotation: Array<any>) => {
  return vendorQuotation?.sort((a: { fee: number }, b: { fee: number }) => a.fee - b.fee)[0]?.fee || 0;
};

export const convertVendorName = (vendorQuotation: Array<any>, vendorCode: string) => {
  return vendorQuotation?.find((vendor: { id: number; code: string }) => vendor.code === vendorCode)?.name;
};

export const getDefaultVendor = (vendorQuotation: Array<any>, vendorCode: string) => {
  return (
    vendorQuotation?.find((vendor: { id: number; code: string }) => vendor.code === vendorCode) ||
    vendorQuotation?.sort((a: { fee: number }, b: { fee: number }) => a.fee - b.fee)[0]
  );
};

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat().format(value);
};

export const convertNumberFormatToNumber = (value: string | number) => {
  return typeof value === 'number' ? value : Number(value?.replace(/,/g, ''));
};

export function isJanpanBusiness(accountType: CustomerAccountType, locationType: CustomerLocationType) {
  return accountType === CustomerAccountType.BUSINESS && locationType === CustomerLocationType.DOMESTIC ? true : false;
}

export const getOrderStatus = (type: number, t: any) => {
  const orderStatus = '';
  switch (type) {
    case OrderStatus.REQUESTED:
      return t('option.requested');
    case OrderStatus.PAYMENT_PENDING:
      return t('option.payment_pending');
    case OrderStatus.PURCHASING:
      return t('option.purchasing');
    case OrderStatus.IMPORTED:
      return t('option.imported');
    case OrderStatus.PAYMENT_CONFIRMED:
      return t('option.confirmed');
    case OrderStatus.DELIVERING:
      return t('option.delivering');
    case OrderStatus.COMPLETED:
      return t('option.completed');
    case OrderStatus.DELIVERY_FAILED:
      return t('option.delivery_failed');
  }
  return orderStatus;
};
