import React from 'react';
import {
  FileWordOutlined,
  DatabaseOutlined,
  SettingFilled,
  ExceptionOutlined,
  PhoneOutlined,
  UserOutlined,
  DesktopOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import DashBoard from 'assets/images/dashboard.svg';
import ListOrder from 'assets/images/listOrder.svg';
import Shipping from 'assets/images/shipping.svg';
import Shopping from 'assets/images/shopping.svg';
import Operator from 'assets/images/operator.svg';
import Refund from 'assets/images/refund.svg';
import Investigation from 'assets/images/investigation.svg';
import ReImport from 'assets/images/reImport.svg';
import ReturnFee from 'assets/images/returnFee.svg';
import WareHouse from 'assets/images/wareHouse.svg';
import Delivery from 'assets/images/delivery.svg';
import Customer from 'assets/images/customer.svg';
import Payment from 'assets/images/payment.svg';
import Employee from 'assets/images/employee.svg';
import Chat from 'assets/images/chat.svg';
import Setting from 'assets/images/setting.svg';

interface IObject {
  [key: string]: any;
}

const IconMaps: IObject = {
  DashBoard: <img src={DashBoard} className="c-sidebar-nav-icon" alt="" />,
  Member: <UserOutlined className="c-sidebar-nav-icon" alt="" />,
  Payment: <img src={Payment} className="c-sidebar-nav-icon" alt="" />,
  Employee: <img src={Employee} className="c-sidebar-nav-icon" alt="" />,
  Chat: <img src={Chat} className="c-sidebar-nav-icon" alt="" />,
  Setting: <img src={Setting} className="c-sidebar-nav-icon" alt="" />,
  Revenue: <FileWordOutlined className="c-sidebar-nav-icon" />,
  Bill: <DatabaseOutlined className="c-sidebar-nav-icon" />,
  SettingFilled: <SettingFilled className="c-sidebar-nav-icon" />,
  Report: <ExceptionOutlined className="c-sidebar-nav-icon" />,
  System: <DesktopOutlined className="c-sidebar-nav-icon" />,
  Contact: <PhoneOutlined className="c-sidebar-nav-icon" />,
  Notification: <NotificationOutlined className="c-sidebar-nav-icon" />,
};

export default IconMaps;
