import { sendGet, sendPut, sendPost } from './axios';

// eslint-disable-next-line import/prefer-default-export
export const getListRoles = (params: any) => sendPost('/permission/role/all', params);

export const addRole = (payload: any) => sendPost('/permission/role', payload);

export const updateRole = (roleId: number, payload: any) => sendPut(`/permission/role/${roleId}`, payload);

export const hiddenRole = (roleId: number, payload: any) =>
  sendPut(`/permission/change-visible-role/${roleId}`, payload);

export const getListPermissionByRole = (roleId: number) => sendGet(`/permission/rolePermission/${roleId}`);

export const updatePermissionByRole = (roleId: number, payload: any) =>
  sendPut(`/permission/rolePermission/${roleId}`, payload);

export const getListPermission = () => sendGet('/permission');
