import { sendGet, sendPost, sendPut } from './axios';

export const getAllResource = (payload: any) => sendGet(`/resource`, payload);

export const getResourceByType = (payload: any) => sendPost(`/resource/all`, payload);

export const updateResourceById = (resourceId: number, payload: any) => sendPut(`/resource/${resourceId}`, payload);

export const createResource = (payload: any) => sendPost(`/resource`, payload);

export const updateResourceIncome = (incomeId: number, payload: any) => sendPut(`/resource/${incomeId}`, payload);

export const creatResourceIncome = (payload: any) => sendPost('/resource', payload);

export const updateResourceFAQ = (faqId: number, payload: any) => sendPut(`/resource/${faqId}`, payload);

export const creatResourceFAQ = (payload: any) => sendPost('/resource', payload);

export const getAllResourceNoAuth = () => sendGet('/resource/all/resource');
