import { sendPut, sendDelete, sendPost, sendGet } from './axios';

export const getListLanguageByEnvironment = (params: any) => sendPost('/language/all', params);

export const getListLanguages = () => {
  return sendGet('/language/lang');
};

export const updateLanguageKey = (key: string, payload: any) => sendPut(`/language/${key}`, payload);

export const addLanguageKey = (payload: any) => sendPut('/cms/language/add-language-key', payload);

export const deleteLanguageKey = (languageKey: any) => sendDelete(`/v1/cms/language/deleteKey/${languageKey}`);

export const uploadFile = (payload: any) => sendPost('/language/upload', payload);

export const getListEnvironments = () => sendGet('/language/env');
export const getListLanguageKey = (params: any) => sendPost('/language/all', params);
