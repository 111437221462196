import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import i18next from 'i18next';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { listLanguageProps } from 'interfaces';

export default function LanguageDropdown() {
  const { t } = useTranslation();
  const [imageFlag, setImageFlag] = useState<string>('');
  const queryClient = useQueryClient();

  const listLanguage: any = queryClient.getQueryData('listLanguages');

  useEffect(() => {
    const currentFlag = listLanguage?.data?.find((language: any) => language.langCode === i18next.language.slice(0, 2))
      ?.langIcon;
    setImageFlag(currentFlag);
  }, [listLanguage]);

  const getListLanguageRender = () => {
    return (
      <Menu style={{ minWidth: 116 }}>
        {listLanguage?.data?.map((language: listLanguageProps, index: number) => {
          return (
            <Menu.Item
              key={index}
              onClick={() => {
                i18next.changeLanguage(language.langCode);
                setImageFlag(language.langIcon);
              }}
            >
              {language.langName}
            </Menu.Item>
          );
        })}
        <Menu.Item
          onClick={() => {
            i18next.changeLanguage('cimode');
          }}
        >
          {`Show key`}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div>
      {i18next.language !== 'cimode' && <img src={imageFlag} style={{ maxWidth: '30px' }} alt={'flag'} />}
      &nbsp;
      <Dropdown overlay={getListLanguageRender} trigger={['click']}>
        <Button style={{ minWidth: 116, marginRight: 16 }}>
          {t('common.language')}
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
}
